const ColorThemeActionTypes = {
  SET_SHOW_DIM_MODE: 'SET_SHOW_DIM_MODE',
  SET_SHOW_CUSTOM_PALETTE: 'SET_SHOW_CUSTOM_PALETTE',
  SET_SHOW_COLOR_SUB_MENU: 'SET_SHOW_COLOR_SUB_MENU',
  SET_SHOW_COLOR_PICKER: 'SET_SHOW_COLOR_PICKER',
  SET_PICKER_COLOR: 'SET_PICKER_COLOR',
  ACTIVATE_COLOR_PALETTE: 'ACTIVATE_COLOR_PALETTE',
  CHANGE_CUSTOM_COLOR: 'CHANGE_CUSTOM_COLOR',
  SET_PICKER_COLOR_OPTIONS: 'SET_PICKER_COLOR_OPTIONS',
};

export default ColorThemeActionTypes;
